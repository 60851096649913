import {AxiosInstance} from 'axios'
import {threadType} from '../types/thread'
import {messageType} from '../types/message'
import MyAxiosInstance from './axiosInstance'
import {AssistantType} from '../types/assistant'
import {IUserInfo} from '../types/user'
import {limitsType} from '../types/limits'
import {subscription} from '../types/subscription'

export class AssistantApi {
    private api: AxiosInstance

    constructor() {
        this.api = MyAxiosInstance
    }

    async getAssistants(): Promise<AssistantType[]> {
        try {
            const res: {data: AssistantType[]} = await this.api.get(
                '/assistants'
            )
            return res.data
        } catch (error) {
            console.log(error)
            const pattern: AssistantType[] = []
            return pattern
        }
    }

    async getThreads(limit = 15, skip = 0): Promise<threadType[]> {
        try {
            const res: {data: threadType[]} = await this.api.get(
                `/threads?limit=${limit}&skip=${skip}`
            )
            return res.data
        } catch (error) {
            console.log(error)
            throw error
        }
    }

    async unarchiveThread(threadId: string): Promise<void> {
        try {
            await this.api.put(`/threads/${threadId}/restore`)
            return
        } catch (error) {
            console.log(error)
            throw error
        }
    }

    async getThread(thread_id: string): Promise<threadType> {
        try {
            const res: {data: threadType} = await this.api.get(
                `/threads/${thread_id}`
            )
            return res.data
        } catch (error) {
            console.log(error)
            throw error
        }
    }

    async getThreadsArchived(): Promise<threadType[]> {
        try {
            const res: {data: threadType[]} = await this.api.get(
                '/threads/archived'
            )
            return res.data
        } catch (error) {
            console.log(error)
            throw error
        }
    }

    async getMessages(thread_id: string): Promise<messageType[]> {
        try {
            const res: {data: messageType[]} = await this.api.get(
                `/threads/${thread_id}/messages`
            )
            return res.data.reverse()
        } catch (error) {
            console.log(error)
            throw error
        }
    }

    async sendMessage(
        thread_id: string,
        content: string,
        assistant_id: string,
        author_id: string
    ): Promise<messageType> {
        try {
            const res: {data: messageType[]} = await this.api.post(
                '/messages',
                {
                    content,
                    thread_id: thread_id === 'new' ? null : thread_id,
                    assistant_id,
                    author_id,
                }
            )
            return res.data[1]
        } catch (error) {
            console.log(error)
            throw error
        }
    }

    async regenerateAnswer(thread_id: string): Promise<messageType> {
        try {
            const res: {data: messageType} = await this.api.put(
                `/threads/${thread_id}/regenerate`
            )
            return res.data
        } catch (error) {
            console.log(error)
            throw error
        }
    }

    async getUser(): Promise<IUserInfo> {
        try {
            const res: {data: IUserInfo} = await this.api.get(`/users`)
            return res.data
        } catch (error) {
            console.log(error)
            throw error
        }
    }

    async archiveThread(threadGuid: string): Promise<void> {
        try {
            await this.api.get(`/threads/${threadGuid}/archive
            `)
            return
        } catch (error) {
            console.log(error)
        }
    }

    async deleteThread(threadGuid: string): Promise<void> {
        try {
            const res = await this.api.delete(`/threads/${threadGuid}/
            `)
            return
        } catch (error) {
            console.log(error)
        }
    }

    async getConstraints(): Promise<{max_characters: number}> {
        try {
            const res = await this.api.get('/users/constraints')
            return res.data
        } catch (error) {
            console.error('Erro ao obter constraints:', error)
            throw 'Erro ao obter constraints da API.'
        }
    }

    async getCurrentSubscription(): Promise<subscription> {
        try {
            const res = await this.api.get('/subscriptions/current')
            return res.data
        } catch (error) {
            console.error('Erro ao obter subscription:', error)
            throw 'Erro ao obter subscription da API.'
        }
    }

    async getLimits(): Promise<limitsType> {
        try {
            const res: {data: limitsType} = await this.api.get(`/users/limits`)
            return res.data
        } catch (error) {
            console.log(error)
            const pattern: limitsType = {
                created_at: '',
                usages: {
                    created_at: '',
                    messages: {
                        max: 0,
                        current: 0,
                    },
                    projects: {
                        max: 0,
                        current: 0,
                    },
                },
            }
            return pattern
        }
    }

    async reportAnswer(content: string, answer_guid: string): Promise<void> {
        try {
            const res: {data: messageType} = await this.api.post(
                `/answers/report`,
                {content, answer_guid}
            )
            console.log('ok', res)
            return
        } catch (error) {
            console.log(error)
            throw error
        }
    }

    async confirmPurchase(CHECKOUT_SESSION_ID: string) {
        try {
            await this.api.get(`/subscriptions/${CHECKOUT_SESSION_ID}/handle`)
            return
        } catch (error) {
            console.log('confirm purchase: ', error)
            throw 403
        }
    }
}
