import {createBrowserRouter, RouterProvider, Navigate} from 'react-router-dom'
import Home from './pages/home'
import UserConfigs from './pages/userConfigs'
import Login from './pages/login'
import AuthProvider from './contexts/auth'
import {CookiesService} from './services/Cookie.service'
import Plans from './pages/plans'
import LandingPage from './pages/lp'
import ValidateEmailPage from './pages/validateEmail'
import ValidateSubscriptionPage from './pages/validateSubscription'
import ResetPassword from './pages/login/components/Form/resetPassword'

function validateToken() {
    const cookiesService = new CookiesService()
    const authData = cookiesService.getAuthData()
    const isAuthenticated = authData?.access_token
    return isAuthenticated
}

const PrivateRoute = (path: string, element: JSX.Element) => {
    const isAuthenticated = validateToken()
    return isAuthenticated ? {path, element} : {path, element: <LandingPage />}
}

const PublicRoute = (path: string, element: JSX.Element) => {
    const isAuthenticated = validateToken() && path.includes('login')
    return isAuthenticated
        ? {path, element: <Navigate to={'/'} />}
        : {path, element}
}

const router = createBrowserRouter([
    PublicRoute('/login', <Login />),
    PrivateRoute('/', <Home />),
    PrivateRoute('/', <LandingPage />),
    PrivateRoute('/configuracoes', <UserConfigs />),
    PublicRoute('/planos', <Plans />),
    PublicRoute('/login/confirm_email', <ValidateEmailPage />),
    PublicRoute('/login/reset_password', <ResetPassword />),
    PublicRoute('/subscriptions/handle', <ValidateSubscriptionPage />),
])

export default function AppRoutes() {
    return (
        <AuthProvider>
            <RouterProvider router={router} />
        </AuthProvider>
    )
}
