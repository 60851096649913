import {useContext, useState} from 'react'
import {AuthContext} from '../../../../../contexts/auth'
import styled from 'styled-components'
import {COLORS} from '../../../../../utils/colors'
import {HistoryContext} from '../../../../../contexts/history'
import {
    Modal,
    Typography,
    IconButton,
    Divider,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    Box,
    TableBody,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined'

const List = styled.ul`
    list-style: none;
    border-radius: 8px;
    padding: 3px;
    position: absolute;
    top: 0;
    transform: translateY(-100%);
    background-color: ${COLORS.cinzaDesbotado};
    max-width: 80%;
    width: 200px;
    z-index: 999;
`

const Item = styled.li<{clickable: boolean; center?: boolean}>`
    border-bottom: none;
    padding: 10px;
    color: ${COLORS.cinzaEscuro};
    font-size: 13px;
    word-break: break-all;
    text-align: ${({center}) => (center ? 'center' : '')};

    &:hover {
        background-color: ${({clickable}) =>
            clickable ? COLORS.branco : 'transparent'};
        cursor: ${({clickable}) => (clickable ? 'pointer' : 'auto')};
    }

    &:last-child {
    }

    &:first-child,
    :first-child:hover {
        background-color: transparent;
        cursor: auto;

        border-bottom: 2px solid ${COLORS.cinzaClaro};
    }
`

const ModalContent = styled.div`
    background-color: white;
    border-radius: 8px;
    width: 100%;
    max-width: 80%;
    max-height: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 24px;
    outline: none;
    display: flex;
    flex-direction: column;

    @media (min-width: 768px) {
        /* Para tablets e maiores */
        width: 60%;
    }

    @media (min-width: 1024px) {
        /* Para telas de desktop */
        width: 40%;
    }
`

const StyledIconButton = styled(IconButton)``

const HeadModal = styled.div`
    display: flex;
    padding: 20px;
    justify-content: space-between;
`

const BodyModal = styled.div`
    aling-content: center;
    display: flex;
    padding: 20px;
    overflow-y: auto;
`

export default function NavList() {
    const {userInfo, logout} = useContext(AuthContext)
    const {
        limits,
        historyArray,
        archivedThread,
        unarchiveThread,
        deleteThread,
        subscription,
        unarchieveModal,
        setUnarchieveModal,
    } = useContext(HistoryContext)

    const dateStr = subscription?.exp_at
    const handleOpen = () => setUnarchieveModal(true)

    let formattedDate = 'Data não disponível'

    if (dateStr) {
        const date = new Date(dateStr)
        formattedDate = date.toLocaleDateString('pt-BR', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
        })
    }

    return (
        <>
            <List>
                <Item clickable={false} center>
                    {userInfo.email}
                </Item>
                <Item clickable={false}>Renova em: {formattedDate}</Item>
                <Item clickable={false}>
                    Uso: {limits.usages.messages.current}/
                    {limits.usages.messages.max}{' '}
                </Item>
                {/* <Item
                    clickable={true}
                    onClick={() => (window.location.pathname = '/configuracoes')}
                    >
                    Configurações
                </Item> */}
                {/* <Item
                    clickable={true}
                    onClick={() => (window.location.href = '/planos')}
                >
                    Assinar
                </Item> */}
                <Item clickable={true} onClick={handleOpen}>
                    Desarquivar Chats
                </Item>
                <Item clickable={true} onClick={logout}>
                    Sair
                </Item>
            </List>
        </>
    )
}
